import * as styles from './industry-landing-page.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';

import Brand from '../components/brand';
import Button from '../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import InteractEmbed from '../components/utils/interact-embed';
import Modal from '../components/modal';
import SEO from '../components/seo';
import Section from '../components/section';
import createImage from '../images/landing-pages/create.gif';
import designImage from '../images/landing-pages/design.gif';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { graphql } from 'gatsby';

const IndustryLandingPageTemplate = ({ data }) => {
  // Initialize page data
  const { industry, heading, description, quiz_id, no_index } = data.quizTemplate.data;

  const [preview, setPreview] = useState();
  
  const metaTitle = !industry ? heading : `${ industry } Quiz Maker`;

  return (
    <>
      <SEO
        noIndex={ no_index }
        title={ `${ metaTitle } | Interact` }
        description={ description }
        template={true}
      />
      <Header/>
      <main className={ styles.industryLandingPage }>
        <Section theme="white" style={ { marginTop: `4.5rem` } }>
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-12 text-center">
              {industry ? (
                <h1 className="heading-xl color-black">What quiz should you make for your <span className="color-purple-500">{ industry.toLowerCase() }</span> business?</h1>
              ) : (
                <h1 className="heading-xl color-black">{heading}</h1>
              )}
            </div>
            <div className="col-12 col-lg-6 text-center">
              <h2 className="heading-sm color-muted-foreground">{ description }</h2>
              <div className="d-flex justify-content-center" style={ { marginTop: `3rem` } }>
                <button
                  className={ [styles.preview, 'd-none d-lg-flex align-items-center'].join(' ') }
                  onClick={ () => setPreview(true) }
                  onKeyPress={ (e) => e.code == 'Space' || e.code == 'Enter' ? setPreview(true) : null }
                  role="button"
                  tabIndex={ 0 }>
                  Take the quiz <FontAwesomeIcon icon={ faArrowRight } style={{ marginLeft: `0.75rem` }}/>
                </button>
                <a
                  href={ `https://quiz.tryinteract.com/#/${ quiz_id }/q/1` }
                  target="_blank"
                  className={ [styles.preview, 'd-flex d-lg-none align-items-center'].join(' ') }>
                  Take the quiz <FontAwesomeIcon icon={ faArrowRight } style={{ marginLeft: `0.75rem` }}/>
                </a>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="white" style={{ paddingTop: '2rem' }}>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="row">
                <div className="col">
                  <p className="text-body color-black text-center">Trusted by leading businesses</p>
                </div>
              </div>
              <div className="row align-items-center justify-content-center justify-content-lg-between">
                <Brand id="jennakutcher-color" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="easytherapy" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="hiqgroup" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="lush-color" className={ `col-auto ${ styles.brand }` }/>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-lg-4">
              <h3 className="heading-xl color-black">Create a quiz with AI</h3>
              <p className="heading-sm color-muted-foreground">Use our AI quiz maker to create a beautiful quiz for your brand in a minute.</p>
            </div>
            <div className="col-12 col-lg-6">
              <div className={styles.featureImage}>
                <img src={createImage} className={styles.designImage} alt="Quiz design options" />
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-between" style={{marginTop: `8rem`}}>
            <div className="col-12 col-lg-4 order-lg-2">
              <h3 className="heading-xl color-black">Match your brand</h3>
              <p className="heading-sm color-muted-foreground">Seamlessly align your quiz aesthetics with your brand identity through our intuitive customization tools. Effortlessly adjust colors and incorporate your logo for a cohesive user experience.</p>
            </div>
            <div className="col-12 col-lg-6 order-lg-1">
              <div className={styles.featureImage}>
                <img src={designImage} className={styles.designImage} alt="Quiz design options" />
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-between" style={{marginTop: `8rem`}}>
            <div className="col-12 col-lg-4">
              <h3 className="heading-xl color-black">Share and embed</h3>
              <p className="heading-sm color-muted-foreground">Seamlessly integrate quizzes across your digital ecosystem. Embed directly into your website or share effortlessly on any platform for maximum reach and engagement.</p>
            </div>
            <div className="col-12 col-lg-6">
              <div className={styles.devicesImages}>
                <div className={styles.featureImage}>
                  <GatsbyImage image={ getImage(data.share1Image) } alt="Interact team group picture"/>
                </div>
                <div className={[styles.featureImage, 'hidden-sm'].join(' ')}>
                  <GatsbyImage image={ getImage(data.share2Image) } alt="Interact team group picture"/>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-between" style={{marginTop: `8rem`}}>
            <div className="col-12 col-lg-4 order-lg-2">
              <h3 className="heading-xl color-black">Connect your software</h3>
              <p className="heading-sm color-muted-foreground">Seamlessly integrate quizzes with your existing email software to collect leads and drive engagement.</p>
            </div>
            <div className="col-12 col-lg-6 order-lg-1">
              <div style={{display: 'grid', gridTemplateColumns: 'repeat(4,minmax(0,1fr))', gap: '2rem'}}>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="Mailchimp" style={{backgroundPosition: '0px -960px'}}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="Mailerlite" style={{backgroundPosition: '0px -1000px'}}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="Flodesk" style={{backgroundPosition: '0px -480px'}}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="Convertkit" style={{backgroundPosition: '0px -240px'}}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="ActiveCampaign"></div>
                </div>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="dotdigital" style={{backgroundPosition: '0px -320px'}}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="Drip" style={{backgroundPosition: '0px -360px'}}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="Klaviyo" style={{backgroundPosition: '0px -880px'}}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="Zapier" style={{backgroundPosition: '0px -1640px'}}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="Thinkific" style={{backgroundPosition: '0px -1560px'}}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="Hubspot" style={{backgroundPosition: '0px -680px'}}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div className={styles.icon} aria-label="Constant Contact" style={{backgroundPosition: '0px -200px'}}></div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={ { padding: `5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-white">Get started today</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p className="heading-sm color-white text-center" style={{ marginTop: '0', marginBottom: '2rem' }}>Use our AI quiz maker to create a beautiful quiz for your brand in a minute.</p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">Make a quiz - for free</Button>
            </div>
          </div>
        </Section>
        { preview ? (
          <Modal className={ styles.previewModal } isOpen={ true } onClose={ () => setPreview(false) }>
            <div className={ styles.content }>
              <InteractEmbed id={ quiz_id } disableCover={ true } />
            </div>
          </Modal>
        ) : null }
      </main>
      <Footer/>
    </>
  )
}

export default IndustryLandingPageTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    quizTemplate: airtable(
      table: {eq: "IndustryLandingPages"}
      data: {slug: {eq: $slug}}
    ) {
      data {
        industry
        slug
        heading
        description
        quiz_id
        no_index
      }
    },
    share1Image: file(relativePath: { eq: "landing-pages/share-1.png" }) {
      childImageSharp {
        gatsbyImageData (
          width: 800,
          quality: 100,
        )
      }
    },
    share2Image: file(relativePath: { eq: "landing-pages/share-2.png" }) {
      childImageSharp {
        gatsbyImageData (
          width: 800,
          quality: 100,
        )
      }
    }
  }
`