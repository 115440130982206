// extracted by mini-css-extract-plugin
export var brand = "industry-landing-page-module--brand--ab896";
export var content = "industry-landing-page-module--content--2d238";
export var designImage = "industry-landing-page-module--design-image--535c5";
export var devicesImages = "industry-landing-page-module--devices-images--4d09b";
export var embed = "industry-landing-page-module--embed--4dd22";
export var embedContainer = "industry-landing-page-module--embed-container--6027c";
export var featureImage = "industry-landing-page-module--feature-image--4c4d5";
export var icon = "industry-landing-page-module--icon--e923d";
export var industryLandingPage = "industry-landing-page-module--industry-landing-page--0e23c";
export var integrationItem = "industry-landing-page-module--integration-item--34fdd";
export var preview = "industry-landing-page-module--preview--339c4";
export var previewModal = "industry-landing-page-module--preview-modal--3c8f4";